import React from 'react'
import { section } from '../../styles/styles'
import { eventDetails2 } from '../../content/cam-tafraout'

const Details = () => {
    return (
        <section className={`${section}`}>
            <div className="wrapper-sm sm:px-6 lg:px-8 max-w-7xl ">
                <div className="grid grid-cols-1 gap-6  xl:gap-10 sm:grid-cols-2 lg:grid-cols-3">
                    {
                        eventDetails2.map(el => {
                            return (


                                <div className="bg-white rounded shadow pt-3" key={el.title}>
                                    <div className="px-8 py-3">
                                        <div className="flex items-center">
                                            {/*  <img className="flex-shrink-0 w-12 h-auto" src="https://cdn.rareblocks.xyz/collection/celebration/images/integration/3/gmail-logo.png" alt="" /> */}
                                            <div className="mr-auto">

                                                <p className="font-ubuntu mb-2 mt-0 capitalize text-xl font-medium text-secondary  lg:text-xl">
                                                    {el.title}
                                                </p>
                                            </div>
                                        </div>
                                        <h4 className="text-xl font-light mt-2 leading-relaxed text-gray-600">{el.value}</h4>
                                    </div>
                                </div>

                            )
                        })
                    }
                </div>


            </div>
        </section>

    )
}

export default Details