import React from 'react'
import { section, bgColor } from '../../styles/styles'
import { eventDetails1 } from '../../content/cam-tafraout'
const Fiche = () => {
    return (
        <section className={`${section}   bg-[#0c1934]`}>
                <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                    <div className="max-w-2xl mx-auto text-center">
                        <h2 className="title sm:text-4xl lg:text-5xl text-gray-100">Fiche signalétiaue</h2>
                        <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600 hidden"></p>
                    </div>

                <div className="grid grid-cols-1 gap-6 mt-12 lg:mt-16 xl:gap-10 sm:grid-cols-2 lg:grid-cols-3">

                    {
                        eventDetails1.map(el => {
                            return (


                                <div className="bg-white rounded shadow pt-3" key={el.title}>
                                    <div className="px-8 py-3">
                                        <div className="flex items-center">
                                            {/*  <img className="flex-shrink-0 w-12 h-auto" src="https://cdn.rareblocks.xyz/collection/celebration/images/integration/3/gmail-logo.png" alt="" /> */}
                                            <div className="mr-auto">

                                                <p className="font-ubuntu mb-2 mt-0 capitalize text-xl font-medium text-secondary  lg:text-xl">
                                                    {el.title}
                                                </p>
                                            </div>
                                        </div>
                                        <h4 className="text-xl font-light mt-2 leading-relaxed text-gray-600">{el.value}</h4>
                                    </div>
                                </div>

                            )
                        })
                    }
                </div>


                </div>
        </section>
    )
}

export default Fiche