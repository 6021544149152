import * as React from 'react'
import Seo from '../components/seo'
import Layout from '../layouts/layout'

import TopImage from '../layouts/camp-tafraout/top-image'
import Fiche from '../layouts/camp-tafraout/fiche'
import Cta from '../components/cta'
import Details from '../layouts/camp-tafraout/details'
import Circuit from '../layouts/camp-tafraout/circuit'

const CampTafraout = () => {
    return (
        <Layout>
            <TopImage />
            <Fiche /> 
            <Circuit />
            <Details />
            <Cta />
        </Layout>
    )
}

export default CampTafraout