export const eventDetails1 = [
    {
        title: 'evénement',
        value: 'TAFRAOUT CAMP (TRAIL & Bike)',
        icon: ''
    },
    {
        title: 'lieu',
        value: 'OUMERGHT (TAFRAOUT)',
        icon: ''
    },
    {
        title: 'date',
        value: '29 & 30 Avril 2023',
        icon: ''
    },
    {
        title: 'organisateur',
        value: 'KNR EVENTS & Association AGADIR VTT',
        icon: ''
    },
    {
        title: 'responsable',
        value: 'Mr NOUREDDINE KARIMI',
        icon: ''
    },
    {
        title: 'staff',
        value: '10 personnes KNR EVENTS & 15 volontaires locaux',
        icon: ''
    },
    {
        title: 'nombre d’équipes',
        value: '60 équipes de 2 personnes ( Binôme) 40 participants en individuel',
        icon: ''
    },
    {
        title: 'logement & restauration',
        value: 'En Bivouac à coté des roches peints',
        icon: ''
    },

]
/*
*/


export const eventDetails2 = [
    {
        title: 'balisage',
        value: 'par flèches de couleur verte + Roadbook',
        icon: ''
    },
    {
        title: 'check point & ravitaillement',
        value: '3 points - km 5+ km 10  + ligne d\'arrivée',
        icon: ''
    },
    {
        title: 'communication & transmission',
        value: '4 Radios ( STAFF KNR EVENTS) + GSM STAFF complet',
        icon: ''
    },
    {
        title: 'départ',
        value: 'Départ des Roches peintes vers Douar Doutalzought et retour via le village d\'Aguerd Oudad soit 16 km .',
        icon: ''
    },
    {
        title: 'arrivée',
        value: 'Roches peintes par équipe (Trailer 1 boucle  de 16 km+ 2 boucles de 32 km pour le Vétetiste le 1er jour et une boucle en binôme de 16 km le 2ème Jour. * En individuel : 1 boucle de 16 km en course à pied + 1 boucle de 16 km en VTT le 1er jour. Et une boucle de 5 km en C à P + 1 boucle de 16 km en VTT le 2ème jour.',
        icon: ''
    },
    {
        title: 'accompagnateurs & sécurité',
        value: '1 VTT ouvreur de course + 1 VTT fermeture de course 2 motos cross pour le contrôle + 1 ambulance de secours + Voiture balai',
        icon: ''
    },
]


export const event = {
    evénement: 'TAFRAOUT CAMP (TRAIL & Bike)',
    lieu: 'OUMERGHT (TAFRAOUT)',
    date: '29 & 30 Avril 2023',
    organisateur: ' KNR EVENTS & Association AGADIR VTT',
    responsable: 'Mr NOUREDDINE KARIMI',
    staff: '10 personnes KNR EVENTS & 15 volontaires locaux',
    'nombre d’équipes': '100 équipes de 2 personnes(Binôme)',
    'logement & restauration': 'En Bivouac à coté des roches peints (Sté ADWAK SOUSS)',
    départ: 'Roches peints vers la vallée TARGA TAGHRARAT & retour via le village AGUERD OUDAD',
    arrivée: 'Roches peints (Trailer 1 boucle + VTT 2 boucles le 1er jours et 1 boucle en binôme le 2ème jour) ',
}