import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { section, btnOutline } from '../../styles/styles'
import { Link } from 'gatsby'


const imagesPath = '../assets'
const Circuit = () => {
    return (
        <section className={`${section} bg-white`}>
            <div className="wrapper-sm sm:px-6 lg:px-8">
                <div className="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
                    <div className='px-4'>
                        <div className='relative'>
                            <p className="font-ubuntu mb-2 mt-0 capitalize text-2xl font-bold text-secondary  lg:text-2xl">Circuit</p>
                            <p className="text-xl font-light mt-2 leading-relaxed text-[#0c1934]">
                                Une boucle de 16 km  reliant le point de départ à partir des Roches peintes vers Douar Doutalzought  et retour via le village emblématique d'Aguerd Oudad.
                            </p>
                        </div>


                        <div className="mt-10 sm:flex sm:items-center sm:space-x-8">
                            <Link to='/inscription'
                                className={btnOutline}
                            >
                                Inscription
                            </Link>

                        </div>
                    </div>

                    <div className='p-3 border bg-white flex justify-center'>
                        <StaticImage
                            className="h-auto w-auto"
                            src={`../${imagesPath}/events/circuit.jpg`}
                            alt="Circuit camp tafraout"
                        />
                    </div>
                </div>
            </div>
        </section >
    )
}

export default Circuit