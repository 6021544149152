import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { section, btnOutline, btnFull } from '../../styles/styles'
import Count from '../../components/count'

import programme from '../../../static/programme-tafraout-camp.pdf'
const imagesPath = '../assets'
const TopImage = () => {
    return (
        <>
            <section className={`${section} pt-3 sm:pt-0`}>
                <div className="wrapper-lg sm:px-6 lg:px-8">
                    <div className="flex flex-col">
                        <div className="relative w-[250px] sm:w-[350px] h-auto px-4 sm:px-4 pt-0 sm:pt-4 mb-4 lg:max-w-fit mx-auto">

                            {/* <div className="bg-gradient-to-b from-white to-transparent absolute z-10 top-0 left-0 w-full h-[30px]"></div> */}
                            <StaticImage
                                className="h-auto max-w-full max-h-full relative rounded-xl"
                                src={`../${imagesPath}/events/camp-tafraout-logo.png`}
                                alt="camp tafraout"
                            />
                        </div>
                        <div className="mx-auto text-center">
                            <h1 className="mt-2 text-3xl tracking-wider sm:text-4xl lg:text-5xl uppercase event font-bold">
                                Tafraout Camp
                                <span className="relative block text-primary">Trail & Bike.</span>
                            </h1>

                           {/*  <div className="">
                                <Count />
                            </div> */}

                            <div className="px-4 space-x-5 sm:items-center sm:justify-center sm:px-0 sm:space-x-5 sm:flex mt-2 z-50 realtive border">
                                <Link
                                    to="/inscription"
                                    className={`${btnOutline} ${btnFull}`}
                                    role="button"
                                >
                                    Inscription
                                </Link>
                            </div>
                        </div>

                    </div>
            </div>


            </section>

            <section className={`${section} bg-white`}>
                <div className="wrapper-sm sm:px-6 lg:px-8">
                    <div className="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
                        <div>
                            <div className='relative'>
                                <h2 className="title sm:text-4xl lg:text-5xl text-[#0c1934]">Tafraout Camp
                                    <span className='block text-primary mt-1'>
                                        TRAIL & BIKE
                                    </span>
                                </h2>
                            </div>

                            <p className="dark">
                                Le staff KNR EVENTS innove toujours dans le monde de la pratique du VTT et du TRAIL en ralliant ces deux disciplines en une seule épreuve pour le plaisir des mordus du sport sur un circuit mythique dans la région de Tafraout, précisément les roches peintes.
                            </p>
                            <p className="dark">
                                Une soixantaine d’équipes formées de trailers et vététistes, entre amateurs et confirmés, seront au rendez-vous du 10 au 12 mars 2023 Inch’Allah pour s’affronter pendant deux journées de compétition en épreuves individuelle ou relais en binôme , sur un circuit méticuleusement balisé et sécurisé.

                            </p>
                            <p className="dark">
                                Un bivouac accueillant et commode avec service de restauration sur place vous attend pour passer un week-end spécial, dans une organisation et logistique à la hauteur de vos espérances.
                                Une ambiance conviviale et bon-enfant est à l’attente de tous les participants voulant un dépaysement et délassement garantis.
                            </p>

                            <div className="mt-6 w-full text-center">
                                <a
                                    href={programme}
                                    className={`${btnOutline} ${btnFull}`}
                                >
                                    CAMPTAFRAOUT Programme
                                </a>
                            </div>
                        </div>
                        <div className="sm:px-0 h-full">
                            <div className="absolute inset-0 h-2/3"></div>
                            <div className="relative mx-auto">
                                <div className="relative sm:px-4 pt-4 lg:max-w-fit mx-auto text-center drop-shadow-xl rounded-t-xl overflow-hidden ">
                                    {/* <div className="bg-gradient-to-b from-white to-transparent absolute z-10 top-0 left-0 w-full h-[30px]"></div> */}
                                    <StaticImage
                                        className="h-auto w-full relative rounded-xl"
                                        src={`../${imagesPath}/events/camp-tafraout-trail-23.jpg`}
                                        alt="camp tafraout"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default TopImage