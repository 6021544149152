import React from 'react'
import { Link } from 'gatsby'
import { section, btnOutline, bgColor } from '../styles/styles'
import Count from './count'
const Cta = () => {
    return (
        <section className={`${section}  bg-[#0c1934]`}>
            <div className="flex flex-col gap-y-2 gap-x-4 flex-wrap sm:flex-row px-4 wrapper-lg sm:px-6 lg:px-8">
                <div className="flex-1 text-center">
                    <h2 className="event text-4xl sm:text-5xl uppercase text-event text-green-500">
                        Tafraout Camp
                        <span className="relative block text-primary">Trail & Bike.</span>
                    </h2>
                    <div className="px-0 space-x-5 sm:items-center sm:justify-center  sm:px-0 sm:space-x-5 sm:flex mt-6">
                        <Link
                            to="/inscription"
                            className={btnOutline}
                            role="button"
                        >
                            Inscription
                        </Link>

                    </div>
                </div>

             {/*    <div className='flex-1' >
                    <Count />
                </div> */}

            </div>
        </section >
    )
}

export default Cta